var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:'orderline-' + _vm.data.item.id + '-cancel-reason',attrs:{"id":'orderline-' + _vm.data.item.id + '-cancel-reason',"cancel-variant":"outline-secondary","ok-title":_vm.$t('Confirm'),"cancel-title":_vm.$t('Cancel'),"centered":"","title":_vm.$t('Why the client canceled the order / article?')},on:{"ok":function($event){return _vm.cancelAllLines(_vm.data.item)}}},[_c('b-form',[_c('b-form-group',[_c('label',{attrs:{"for":"reason"}},[_vm._v(_vm._s(_vm.$t("Reason"))+":")]),_c('b-form-input',{attrs:{"id":"reason","type":"text","placeholder":_vm.$t('Reason')},model:{value:(_vm.cancelAllLineReason),callback:function ($$v) {_vm.cancelAllLineReason=$$v},expression:"cancelAllLineReason"}})],1)],1)],1),_c('b-modal',{ref:"orderlines-cancel-reason",attrs:{"cancel-variant":"outline-secondary","ok-title":_vm.$t('Confirm'),"cancel-title":_vm.$t('Cancel'),"centered":"","title":_vm.$t('Why the client canceled the order / article?')},on:{"ok":function($event){return _vm.onConfirmCancelingOrderLine(_vm.data.item)}}},[_c('b-form',[_c('b-form-group',[_c('label',{attrs:{"for":"reason"}},[_vm._v(_vm._s(_vm.$t("Reason"))+":")]),_c('b-form-input',{attrs:{"id":"reason","type":"text","placeholder":_vm.$t('Reason')},model:{value:(_vm.cancelSelectedLineReason),callback:function ($$v) {_vm.cancelSelectedLineReason=$$v},expression:"cancelSelectedLineReason"}})],1)],1)],1),_c('b-modal',{ref:'orderline-' + _vm.data.item.id + '-cancel-line',attrs:{"cancel-variant":"outline-primary","cancel-title":_vm.$t('Cancel'),"ok-title":_vm.$t('Confirm'),"centered":"","size":"xl","title":_vm.$t('Cancel order line by line')},on:{"hidden":_vm.onCancelOrderLineHidden,"ok":_vm.confirmCancelingOrderLine}},[_c('b-table',{attrs:{"show-empty":"","empty-text":_vm.$t('No matching records found'),"selectable":"","select-mode":"multi","items":_vm.orderLines,"fields":[
        'selected',
        'product',
        'shipping_type',
        'packing',
        'category',
        'glazurage',
        'brand',
        'packaging',
        'tranche',
        'lot',
        'ordered_qty',
        'available_qty',

        'unity',
        'state' ],"responsive":""},on:{"row-selected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"cell(selected)",fn:function(ref){
      var rowSelected = ref.rowSelected;
return [(rowSelected)?[_c('i',{staticClass:"feather icon-disc primary"})]:[_c('i',{staticClass:"feather icon-circle"})]]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }