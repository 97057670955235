<template>
  <div>
    <b-modal
      :ref="'consulte-order-' + data.item.id"
      centered
      size="xl"
      @hidden="onConsulteOrderModalHidden"
      :title="$t('Consultation')"
      hide-footer
    >
      <b-table
        select-mode="multi"
        show-empty
        :empty-text="$t('No matching records found')"
        :items="orderLines"
        :fields="fields"
        responsive
        @row-selected="onRowSelected"
      >
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="
                !data.item.duplicate &&
                parseFloat(data.item.lot_qty) <
                  parseFloat(data.item.available_qty)
              "
              @click="onDuplicteRowClick(data.item)"
            >
              <feather-icon
                icon="CopyIcon"
                size="18"
                class="align-middle text-body"
              />
              <span class="align-middle ml-50">Duplicate</span>
            </b-dropdown-item>
            <b-dropdown-item @click="onEditRowClick(data.item)">
              <feather-icon
                icon="EditIcon"
                size="18"
                class="align-middle text-body"
              />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <!-- <b-dropdown-item @click="onRowSelected(data.item)">
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
                class="align-middle text-body"
              />
              <span class="align-middle ml-50">Verified</span>
            </b-dropdown-item> -->

            <b-dropdown-item @click="onUnCheckedRowClick(data.item)">
              <feather-icon
                icon="XCircleIcon"
                size="18"
                class="align-middle text-body"
              />
              <span class="align-middle ml-50">Unconfirmed</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-modal>

    <b-modal
      ref="change-quantity"
      cancel-variant="outline-secondary"
      :ok-title="$t('Confirm')"
      :cancel-title="$t('Cancel')"
      centered
      @hidden="onChangeQuantityModalHidden"
      @ok="confirmChangeoOrderlineQuantity"
      size="lg"
      :title="$t('Alert')"
    >
      <b-form>
        <b-row>
          <b-col class="col-12">
            <b-form-group :label="$t('Quantity')">
              <b-form-input
                v-on:keypress="PositiveNumbersOnly"
                id="quantity"
                type="text"
                v-model="lot_qte"
                :placeholder="$t('Quantity')"
              />
            </b-form-group>
          </b-col>
          <b-col class="col-12">
            <b-form-group :label="$t('Lot')">
              <b-form-input
                id="lot"
                type="text"
                v-model="lot"
                :placeholder="$t('Lot')"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <b-modal
      ref="change-storekeeper"
      cancel-variant="outline-secondary"
      :ok-title="$t('Confirm')"
      :cancel-title="$t('Cancel')"
      centered
      @hidden="onChangeStorekeeperModalHidden"
      @ok="confirmChangeoOrderlineStorekeeper"
      size="lg"
      :title="$t('Alert')"
    >
      <b-form>
        <b-row>
          <b-col class="col-12">
            <b-form-group :label="$t('Lot')">
              <b-form-input
                id="lot"
                type="text"
                v-model="lot"
                :placeholder="$t('Lot')"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BPagination,
  BDropdown,
  BDropdownItem,
  BModal,
  VBModal,
  BCardText,
  BBadge,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import instance from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
    BMedia,
    BBadge,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    flatPickr,
    BPagination,
    BModal,
    VBModal,
    BCardText,
    BFormInvalidFeedback,
  },
  props: {
    data: Object,
    stateId: String,
  },
  data() {
    return {
      orderLines: [],
      order: null,
      lot: null,
      selectedOrderLine: null,
      refetchData: null,
      lot_qte: null,
      fields: [
        "storekeeper",
        "product",
        "shipping_type",
        "packing",
        "category",
        "glazurage",
        "brand",
        "packaging",
        "tranche",
        "lot",
        "ordered_qty",
        "piece",
        "available_qty",
        "lot_qty",
        "unity",
        "state",
        "actions",
      ],
    };
  },
  methods: {
    PositiveNumbersOnly(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async confirmChangeoOrderlineQuantity(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (!this.lot) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("Lot is required"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
        return;
      }
      if (!this.lot_qte) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("Quantity is required"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
        return;
      }

      if (this.lot_qte > this.selectedOrderLine.available_qty) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t(
              "Lot quantity must be be less than available quantity"
            ),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
        return;
      }

      await instance.patch(
        `/orders/order-lines/${this.selectedOrderLine.id}/storehead/`,
        {
          action: "quantity",
          quantity: this.lot_qte,
          lot: this.lot,
        }
      );

      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("Success"),
          text: this.$t("Order line lot has been changed"),
          icon: "CheckIcon",
          variant: "success",
        },
      });
      await this.consulteOrder(this.order, this.refetchData);
      this.lot_qte = null;
      this.$refs["change-quantity"].hide();
    },
    async onEditRowClick(orderLine) {
      this.lot = orderLine.lot;
      this.lot_qte = orderLine.lot_qty;
      this.selectedOrderLine = orderLine;
      this.$refs["change-quantity"].show();
    },
    async onUnCheckedRowClick(orderLine) {
      const result = await this.$bvModal.msgBoxConfirm(
        this.$t("Are you sure you want to unconfirmed the line you selected?"),
        {
          title: "Alert",
          size: "sm",
          okVariant: "primary",
          okTitle: this.$t("Yes"),
          cancelTitle: this.$t("No"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      );
      if (!result) {
        return;
      }
      await instance.patch(`/orders/order-lines/${orderLine.id}/storehead/`, {
        action: "unverify",
      });
      await this.consulteOrder(this.order, this.refetchData);
    },
    async onDuplicteRowClick(orderLine) {
      const result = await this.$bvModal.msgBoxConfirm(
        this.$t("Are you sure you want to duplicate the line you selected?"),
        {
          title: "Alert",
          size: "sm",
          okVariant: "primary",
          okTitle: this.$t("Yes"),
          cancelTitle: this.$t("No"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      );
      if (!result) {
        return;
      }
      await instance.patch(`/orders/order-lines/${orderLine.id}/storehead/`, {
        action: "duplicate",
      });
      await this.consulteOrder(this.order, this.refetchData);
    },
    async confirmChangeoOrderlineStorekeeper(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (!this.lot) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("Lot is required"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
        return;
      }

      await instance.post(
        `/orders/order-lines/${this.selectedOrderLine.id}/storehead/`,
        {
          lot: this.lot,
          state: this.stateId,
        }
      );

      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("Success"),
          text: this.$t("Order line lot has been changed"),
          icon: "CheckIcon",
          variant: "success",
        },
      });
      await this.consulteOrder(this.order, this.refetchData);
      this.lot = null;
      this.$refs["change-storekeeper"].hide();
    },
    onConsulteOrderModalHidden() {
      this.orderLines = [];
      this.refetchData();
    },
    onChangeStorekeeperModalHidden() {
      this.selectedStorekeeper = null;
      this.quantity = null;
    },
    onChangeQuantityModalHidden() {
      this.quantity = null;
    },

    async onRowSelected(orderLine) {
      this.selectedOrderLine = orderLine;
      this.$refs["change-storekeeper"].show();
    },
    onCancelOrderLineHidden() {
      this.orderLines = [];
    },
    async consulteOrder(order, refetchData) {
      this.refetchData = refetchData;
      try {
        this.orderLines = [];
        this.order = order;

        const res = await instance.get(`/orders/${order.id}/order-lines/`, {
          params: { state_id: this.stateId, role: "storehead" },
        });

        res.data.forEach((orderLine) => {
          const {
            product_name,
            packing,
            category,
            glazurage,
            brand,
            packaging,
            tranche,
            unity,
          } = orderLine.product_combination;
          const {
            state,
            ordered_qty,
            piece,
            available_qty,
            lot_qty,
            shipping_type,
            shipping_date,
            storehead_agent,
            storekeeper_agent,
            lot,
            duplicate,
          } = orderLine;
          this.orderLines.push({
            id: orderLine.id,
            product: product_name.name,
            packing: packing.name,
            category: category.name,
            glazurage: glazurage.name,
            brand: brand.name,
            packaging: packaging.name,
            tranche: tranche.name,
            unity: unity.name,
            state: state.name,
            lot: lot,
            ordered_qty: ordered_qty,
            piece: piece,
            available_qty: available_qty,
            shipping_type: shipping_type.name,
            shipping_type_id: shipping_type,
            shipping_date: `${shipping_date}`,
            storehead: storehead_agent.name,
            storekeeper: storekeeper_agent?.name,
            lot_qty: lot_qty,
            duplicate: duplicate,
          });
        });

        this.$refs[`consulte-order-${order.id}`].show();
      } catch (err) {
        const error = err.response
          ? Object.values(err.response.data)[0][0]
          : err.message;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: error ?? this.$t("An error has occurred. Please try again"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
  },
};
</script>
