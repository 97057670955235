<template>
  <div>
    <b-modal
      ref="prepare-order-lines"
      cancel-variant="outline-secondary"
      centered
      size="lg"
      :title="$t('Alert')"
      @hidden="selectedOrderLineLot = []"
      @ok="prepareSelectedOrderLines"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group :label="$t('Storekeeper')" style="font-size: 0.857rem">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              input-id="sub-profile"
              :options="storekeeper"
              v-model="selectedStorekeeper"
              label="name"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      ref="prepare-all-order-lines"
      cancel-variant="outline-secondary"
      centered
      size="lg"
      @hidden="onPrepareAllOrderLinesHidden"
      @ok="prepareAllOrderLines"
      :title="$t('Alert')"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group :label="$t('Storekeeper')" style="font-size: 0.857rem">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              input-id="sub-profile"
              :options="storekeeper"
              v-model="selectedStorekeeper"
              label="name"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      :ref="'orderline-' + data.item.id + '-prepare-line'"
      cancel-variant="outline-primary"
      :cancel-title="$t('Cancel')"
      :ok-title="$t('Prepare')"
      centered
      size="xl"
      :title="$t('Alert')"
      @hidden="onPrepareOrderLineHidden"
      @ok="confirmPreparingOrderLine"
    >
      <b-table
        show-empty
        :empty-text="$t('No matching records found')"
        selectable
        select-mode="multi"
        :items="orderLines"
        :fields="[
          'selected',
          'product',
          'ref',
          /*'shipping_type',
          'packing',
          'category',
          'glazurage',
          'brand',
          'packaging',
          'tranche',
          'lot',*/
          'ordered_qty',
          'available_qty',
          'piece',
          'available_piece',
          'shipping_type',
          //'unity',
          'state',
        ]"
        responsive
        @row-selected="onRowSelected"
      >
        <template #cell(selected)="{ rowSelected }">
          <template v-if="rowSelected">
            <i class="feather icon-disc primary" />
          </template>

          <template v-else>
            <i class="feather icon-circle" />
          </template>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BPagination,
  BDropdown,
  BDropdownItem,
  BModal,
  VBModal,
  BCardText,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import instance from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
    BMedia,
    BBadge,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    flatPickr,
    BPagination,
    BModal,
    VBModal,
    BCardText,
  },
  props: {
    data: Object,
    stateId: String,
  },
  data() {
    return {
      selectedOrderLine: [],
      orderLines: [],
      selectedStorekeeper: null,
      refetchData: null,
      storekeeper: [],
    };
  },

  methods: {
    onPrepareAllOrderLinesHidden() {
      this.orderLines = [];
      this.selectedOrderLineLot = [];
    },
    async prepareAllOrderLines() {
      if (!this.selectedStorekeeper) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("You must select storekeeper."),
            icon: "InfoIcon",
            variant: "warning",
          },
        });
        return;
      }
      try {
        const data = this.getPreparedOrderLines();
        await instance.post(`/orders/${this.data.item.id}/storekeepers/`, data);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Success"),
            text: this.$t("All order lines have been prepared"),
            icon: "CheckIcon",
            variant: "success",
          },
        });

        this.refetchData();
        this.$refs["prepare-all-order-lines"].hide();
      } catch (err) {
        const error = err.response
          ? Object.values(err.response.data)[0][0]
          : err.message;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: error ?? this.$t("An error has occurred. Please try again"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    async prepareSelectedOrderLines(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (!this.selectedStorekeeper) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("You must select storekeeper."),
            icon: "InfoIcon",
            variant: "warning",
          },
        });
        return;
      }

      try {
        const data = this.getPreparedOrderLines();
        await instance.post(`/orders/${this.data.item.id}/storekeepers/`, data);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Success"),
            text: this.$t("All order lines have been prepared"),
            icon: "CheckIcon",
            variant: "success",
          },
        });

        this.orderLines = [];
        this.refetchData();
        await this.openCancelingOrderReasonByLineModel(this.data.item);

        this.$refs["prepare-order-lines"].hide();
      } catch (err) {
        const error = err.response
          ? Object.values(err.response.data)[0][0]
          : err.message;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: error ?? this.$t("An error has occurred. Please try again"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    getPreparedOrderLines() {
      let data = {
        storekeeper: null,
        order_lines: [],
      };
      data.storekeeper = parseInt(this.selectedStorekeeper.id);

      for (var index in this.selectedOrderLine) {
        data.order_lines.push({
          id: parseInt(this.selectedOrderLine[index].id),
        });
      }

      return data;
    },

    async loadOrderStoreHeads(order) {
      const res = await instance.get(`/orders/${order.id}/storekeepers/`);
      this.storekeeper = res.data;
    },
    confirmPreparingOrderLine(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (this.selectedOrderLine.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("You must select at least 1 order line."),
            icon: "InfoIcon",
            variant: "warning",
          },
        });
        return;
      }
      this.$refs["prepare-order-lines"].show();
    },
    onRowSelected(items) {
      this.selectedOrderLine = items;
    },
    onPrepareOrderLineHidden() {
      this.orderLines = [];
    },
    async prepareOrder(order, refetchData) {
      this.refetchData = refetchData;
      const result = await this.$bvModal.msgBoxConfirm(
        this.$t("Please choose the suitable action for you."),
        {
          title: "Alert",
          size: "sm",
          okVariant: "primary",
          okTitle: this.$t("All lines"),
          cancelTitle: this.$t("By line"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      );

      if (result === null || result === undefined) {
        return;
      }

      try {
        await this.openCancelingOrderReasonByLineModel(order);
        await this.loadOrderStoreHeads(order);

        if (result) {
          this.openCancelingOrderReasonModel(order);
        } else {
          this.$refs[`orderline-${order.id}-prepare-line`].show();
        }
      } catch (err) {
        const error = err.response
          ? Object.values(err.response.data)[0][0]
          : err.message;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: error ?? this.$t("An error has occurred. Please try again"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },

    openCancelingOrderReasonModel(order) {
      this.selectedOrderLine = this.orderLines;
      this.$refs["prepare-all-order-lines"].show();
    },
    async openCancelingOrderReasonByLineModel(order) {
      const res = await instance.get(`/orders/${order.id}/order-lines/`, {
        params: { state_id: this.stateId, role: "storehead" },
      });

      res.data.forEach((orderLine) => {
        const {
          product_name,
          packing,
          category,
          glazurage,
          brand,
          packaging,
          tranche,
          unity,
          selling_type,
        } = orderLine.product_combination;
        const {
          state,
          ordered_qty,
          piece,
          available_qty,
          available_piece,
          shipping_type,
        } = orderLine;
        this.orderLines.push({
          id: orderLine.id,
          product:
            product_name.name +
            " | " +
            tranche.name +
            " | " +
            glazurage.name +
            " | " +
            packaging.name +
            " | " +
            packing.name +
            " | " +
            unity.name +
            " | " +
            selling_type.name +
            " | " +
            brand.name +
            " | " +
            category.name,

          /*packing: packing.name,
                  category: category.name,
                  glazurage: glazurage.name,
                  brand: brand.name,
                  packaging: packaging.name,
                  tranche: tranche.name,
                  unity: unity.name,*/
          state: state.name,
          ordered_qty: ordered_qty,
          piece: piece,
          available_qty: available_qty,
          available_piece: available_piece,
          shipping_type: shipping_type.name,
          selected: true,
          ref: order.ref,
        });
      });
    },
  },
};
</script>
