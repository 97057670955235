<template>
  <div>
    <b-modal
      :ref="'orderline-' + data.item.id + '-cancel-reason'"
      :id="'orderline-' + data.item.id + '-cancel-reason'"
      cancel-variant="outline-secondary"
      :ok-title="$t('Confirm')"
      :cancel-title="$t('Cancel')"
      centered
      :title="$t('Why the client canceled the order / article?')"
      @ok="cancelAllLines(data.item)"
    >
      <b-form>
        <b-form-group>
          <label for="reason">{{ $t("Reason") }}:</label>
          <b-form-input
            id="reason"
            type="text"
            v-model="cancelAllLineReason"
            :placeholder="$t('Reason')"
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal
      ref="orderlines-cancel-reason"
      cancel-variant="outline-secondary"
      :ok-title="$t('Confirm')"
      :cancel-title="$t('Cancel')"
      centered
      :title="$t('Why the client canceled the order / article?')"
      @ok="onConfirmCancelingOrderLine(data.item)"
    >
      <b-form>
        <b-form-group>
          <label for="reason">{{ $t("Reason") }}:</label>
          <b-form-input
            id="reason"
            type="text"
            v-model="cancelSelectedLineReason"
            :placeholder="$t('Reason')"
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal
      :ref="'orderline-' + data.item.id + '-cancel-line'"
      cancel-variant="outline-primary"
      :cancel-title="$t('Cancel')"
      :ok-title="$t('Confirm')"
      centered
      size="xl"
      :title="$t('Cancel order line by line')"
      @hidden="onCancelOrderLineHidden"
      @ok="confirmCancelingOrderLine"
    >
      <b-table
        show-empty
        :empty-text="$t('No matching records found')"
        selectable
        select-mode="multi"
        :items="orderLines"
        :fields="[
          'selected',
          'product',
          'shipping_type',
          'packing',
          'category',
          'glazurage',
          'brand',
          'packaging',
          'tranche',
          'lot',
          'ordered_qty',
          'available_qty',

          'unity',
          'state',
        ]"
        responsive
        @row-selected="onRowSelected"
      >
        <template #cell(selected)="{ rowSelected }">
          <template v-if="rowSelected">
            <i class="feather icon-disc primary" />
          </template>

          <template v-else>
            <i class="feather icon-circle" />
          </template>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BPagination,
  BDropdown,
  BDropdownItem,
  BModal,
  VBModal,
  BCardText,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import instance from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
    BMedia,
    BBadge,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    flatPickr,
    BPagination,
    BModal,
    VBModal,
    BCardText,
  },
  props: {
    data: Object,
    stateId: String,
  },
  data() {
    return {
      cancelAllLineReason: "",
      cancelSelectedLineReason: "",
      selectedOrderLine: [],
      orderLines: [],
    };
  },

  methods: {
    async onConfirmCancelingOrderLine(order) {
      try {
        await instance.put(`/orders/${order.id}/cancel/`, {
          reason: this.cancelSelectedLineReason,
          orderlines: this.selectedOrderLine.map((e) => e.id),
        });

        this.$refs[`orderline-${order.id}-cancel-line`].hide();
        this.$refs[`orderlines-cancel-reason`].hide();

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Success"),
            text: this.$t("All order lines have been canceled"),
            icon: "CheckIcon",
            variant: "success",
          },
        });
      } catch (err) {
        const error = err.response
          ? Object.values(err.response.data)[0][0]
          : err.message;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: error ?? this.$t("An error has occurred. Please try again"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.cancelSelectedLineReason = "";
      }
    },
    confirmCancelingOrderLine(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (this.selectedOrderLine.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("You must selecte at least 1 order line."),
            icon: "InfoIcon",
            variant: "warning",
          },
        });
        return;
      }
      this.$refs[`orderlines-cancel-reason`].show();
    },
    onRowSelected(items) {
      this.selectedOrderLine = items;
    },
    onCancelOrderLineHidden() {
      this.orderLines = [];
    },
    async cancelOrder(order) {
      const result = await this.$bvModal.msgBoxConfirm(
        this.$t("Please choose the suitable action for you."),
        {
          title: "Alert",
          size: "sm",
          okVariant: "primary",
          okTitle: this.$t("All lines"),
          cancelTitle: this.$t("By line"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      );

      if (result === null || result === undefined) {
        return;
      }

      if (result) {
        this.openCancelingOrderReasonModel(order);
        return;
      }

      this.openCancelingOrderReasonByLineModel(order);
    },
    openCancelingOrderReasonModel(order) {
      this.$refs[`orderline-${order.id}-cancel-reason`].show();
    },
    async openCancelingOrderReasonByLineModel(order) {
      try {
        const res = await instance.get(`/orders/${order.id}/order-lines/`, {
          params: { state_id: this.stateId, role: "storehead" },
        });

        res.data.forEach((orderLine) => {
          const {
            product_name,
            packing,
            category,
            glazurage,
            brand,
            packaging,
            tranche,
            unity,
          } = orderLine.product_combination;
          const { state, ordered_qty, available_qty, shipping_type } =
            orderLine;
          this.orderLines.push({
            id: orderLine.id,
            product: product_name.name,
            shipping_type: "",
            packing: packing.name,
            category: category.name,
            glazurage: glazurage.name,
            brand: brand.name,
            packaging: packaging.name,
            tranche: tranche.name,
            unity: unity.name,
            state: state.name,
            ordered_qty: ordered_qty,
            available_qty: available_qty,
            shipping_type: shipping_type.name,
            selected: true,
          });
        });

        this.$refs[`orderline-${order.id}-cancel-line`].show();
      } catch (err) {
        const error = err.response
          ? Object.values(err.response.data)[0][0]
          : err.message;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: error ?? this.$t("An error has occurred. Please try again"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    async cancelAllLines(order) {
      try {
        await instance.post(`/orders/${order.id}/cancel/`, {
          reason: this.cancelAllLineReason,
        });
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Success"),
            text: this.$t("All order lines have been canceled"),
            icon: "CheckIcon",
            variant: "success",
          },
        });
      } catch (err) {
        const error = err.response
          ? Object.values(err.response.data)[0][0]
          : err.message;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: error ?? this.$t("An error has occurred. Please try again"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.cancelAllLineReason = "";
      }
    },
  },
};
</script>
